<template>
    <div style="display: inline-block">
        <b-button @click="getLogo" v-b-modal="item.empresa.cnpj" variant="info"><i class="fa fa-eye"></i></b-button>
        <b-modal size="xl" :id="item.empresa.cnpj" :title="item.empresa.razao" ok-only>
            <h4>{{ item.empresa.cnpj | VMask(['##.###.###/####-##']) }}</h4>
            <b-overlay :show="logo_url==false">
                <img v-if="logo_url" :src="logo_url" style="height: 200px; width: auto"/>
            </b-overlay>
            <p>
                <strong>Fantasia: </strong>{{ item.empresa.fantasia }}<br>
                <strong>Razão: </strong>{{ item.empresa.razao }}<br>
                <strong>Cnpj: </strong>{{ item.empresa.cnpj }}<br>
                <strong>Site: </strong>{{ item.empresa.site }}<br>
                <strong>Endereço: </strong>{{ item.empresa.endereco }}, {{ item.empresa.numero }} {{ item.empresa.complemento }}, {{ item.empresa.bairro }}, {{ item.empresa.cidade }} - {{ item.empresa.estado }}<br>
                <strong>Area atuação: </strong>{{ item.empresa.area_atuacao }}<br>
                <strong>Receita: </strong>{{ item.empresa.receita }}<br>
                <strong>Funcionarios qtd: </strong>{{ item.empresa.funcionarios_qtd }}<br>
                <strong>Descrição: </strong>{{ item.empresa.descricao }}
            </p>
            <h5>Responsável</h5>
            <p>
                <strong>Nome: </strong>{{ item.pessoa.nome }}<br>
                <strong>CPF: </strong>{{ item.pessoa.cpf }}<br>
                <strong>Cargo: </strong>{{ item.pessoa.cargo }}<br>
                <strong>E-mail: </strong>{{ item.pessoa.email }}<br>
                <strong>Telefone: </strong>{{ item.pessoa.ddd }} - {{ item.pessoa.fone }}
            </p>


        </b-modal>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
    name: "modalCnpj",
    props: ['item'],
    data() {
        return {
            logo_url: false,
        }
    },
    methods: {
        getLogo() {
            if (this.logo_url !== false) return;
            window.dataObject = this;
            var storageRef = firebase.storage().ref();
            storageRef.child(this.item.empresa.logo_v1).getDownloadURL().then(function (url) {
                window.dataObject.logo_url = url;
            }).catch(function (error) {
                console.log(error);
            });
        },
    }
}
</script>

<style scoped>

</style>